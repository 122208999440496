.skill {
  h5:hover:not(:active) {
    opacity: 0.7 !important;
  }

  h5:active {
    opacity: 0.6 !important;
  }
}

.skill-checkout {
  transition: 0.3s ease-in-out transform;
}

.skill-checkout:not(.enabled) {
  transform: translateX(20em);
}
