@media (max-width: 767px) {
  .bg-img {
    background-size: 1534px !important;
    background-position: top !important;
  }
}

.bg-img {
  background-color: #fffa;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.divider {
  padding-top: 1.5em;
  opacity: 0.7;
}

.clickable-subtle {
  cursor: pointer;
}

.clickable-subtle:hover {
  opacity: 0.9;
}
