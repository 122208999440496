@import url('https://fonts.googleapis.com/css?family=Kanit:200,400|Assistant:200,400,700');

$font-family-sans-serif: 'Kanit', sans-serif;
$headings-font-family: 'Assistant', sans-serif;
$headings-font-weight: 200;

$spacer: 1rem;

//$primary: #7D61C8;
//$secondary: #ffffff;
//$light: #f1f1f1;
//$dark: #000000;
